import * as React from 'react';
import { FC, PropsWithChildren, useRef, useState } from 'react';
import { Popper } from '@mui/base';
import { css, styled } from '@mui/system';

interface Props {
  popupText: string
}

const Popup: FC<PropsWithChildren<Props>> = ({ children, popupText }) => {
  const anchorEl = useRef();
  const [open, setOpen] = useState(false)

  return (
    // @ts-ignore
    <div ref={anchorEl} onMouseEnter={() => setOpen(true)} onMouseLeave={() => setOpen(false)}>
      {children}
      <Popper open={open} anchorEl={anchorEl.current}>
        <StyledBox>{popupText}</StyledBox>
      </Popper>
    </div>
  );
}

export default Popup

const StyledBox = styled('div')(
  ({ theme }) => css`
    background-color: #ddd;
    padding: 4px;
  `,
);
