import { Box } from "@mui/material";
import React, { FC, useState } from "react";

interface DateRange {
  beginDate: string,
  endDate: string,
}

interface Props {
  onChange?: (dateRange: DateRange) => void
}

const DateRange:FC<Props> = ({onChange}) => {
  const [dateRange, setDateRange] = useState({
    beginDate: "",
    endDate: "",
  })

  const setDate = (type: "beginDate" | "endDate", value: string) => {
    const anotherType = type === "beginDate" ? "endDate" : "beginDate"
    setDateRange(prev => {
      const newDateRange = {
        ...prev,
        [type]: value,
      }
      if (new Date(newDateRange.beginDate) > new Date(newDateRange.endDate)) {
        newDateRange[anotherType] = newDateRange[type]
      }
      onChange?.(newDateRange)
      return newDateRange
    })
  }

  return(
    <Box display="flex" flexDirection="column">
      <Box display="flex" mb="4px">
        <span style={{marginRight: "15px"}}>с:</span>
        <input
          type="date"
          id="beginDate"
          value={dateRange.beginDate}
          onChange={e => setDate("beginDate", e.target.value)}
        />
      </Box>
      <Box display="flex">
        <span style={{marginRight: "6px"}}>по:</span>
        <input
          type="date"
          id="endDate"
          value={dateRange.endDate}
          onChange={e => setDate("endDate", e.target.value)}
        />
      </Box>
    </Box>
  )
}

export default DateRange