import { createContext, FC, PropsWithChildren, useContext, useState } from "react";
import { AlertColor } from "@mui/material";

export interface Toast {
  id: number,
  text: string,
  type: AlertColor,
}

export interface ToastState {
  lastId: number,
  messages: Toast[]
}

interface ToastContext {
  state: ToastState,
  success: (text: string) => void,
  error: (text: string) => void,
  info: (text: string) => void,
  warning: (text: string) => void,
  remove: (id: number) => void,
}

const ToastContext = createContext<ToastContext | undefined>(undefined);

export const ToastContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [state, setState] = useState<ToastState>({ lastId: 0, messages: [] });

  const toastAdd = (type: AlertColor) => (text: string = "") => {
    setState(prev => {
      const { messages, lastId } = prev;
      setTimeout(() => {
        toastRemove(lastId);
      }, 5000);

      return {
        messages: [...messages, { text, type, id: lastId }],
          lastId: lastId + 1,
      }
    })
  }

  const toastRemove = (id: number) => setState(prev => ({
    lastId: prev.lastId,
    messages: prev.messages.filter(message => message.id !== id),
  }));

  const toast = {
    state,
    success: toastAdd("success"),
    error: toastAdd("error"),
    info: toastAdd("info"),
    warning: toastAdd("warning"),
    remove: toastRemove,
  };

  return <ToastContext.Provider value={toast}>{children}</ToastContext.Provider>;
};

export const useToast = () => {
  const context = useContext(ToastContext);
  if (context === undefined) {
    throw new Error("useToast must be used within a ToastContextProvider");
  }

  return context;
};