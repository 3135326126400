import { Box, Typography } from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { FC } from "react";

interface Props {
  error?: boolean,
  success?: boolean,
  text: string,
}

const InfoMessage:FC<Props> = ({error, success, text}) => {
  const icon = error ? <ErrorIcon color="warning"/> : <CheckCircleIcon color="success"/>

  return(
    <Box display="flex">
      {icon}
      <Typography>{text}</Typography>
    </Box>
  )
}

export default InfoMessage;