import { Box, styled, css } from "@mui/material";

export const PageContainer = styled(Box)`
  height: 98vh;
  width: 100%;
  min-width: 1000px;
  background-color: #F6F6F6;
`;

export const ContentContainer = styled(Box)({
  padding: "16px 28px",
  display: "flex",
  width: "100%",
  height: "100%",
  "&>*:not(:last-child)": {
    marginRight: "12px",
  }
});

export const ColumnContainer = styled(Box)`
  ${({ theme }) => css`
    background-color: #FFFFFF;
    padding: 12px;
    display: flex;
    flex: 1;
    flex-direction: column;
    max-width: 33%;
    height: 100%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
  `}
`;

export const ListItem = styled(Box)({
  padding: "16px 28px",
  display: "flex",
  width: "100%",
  height: "100%",
  "&>*:not(:last-child)": {
    marginRight: "12px",
  }
});

export const BoxUpload = styled(Box)(({ theme }) => ({
  margin: 6,
  height: "350px",
  width: "600px",
  border: "3px dashed gray",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",

  // [theme.breakpoints.down("mobile")]: {
  //   margin: 0,
  //   color: "#BDBDBD",
  //   border: "1px dashed #BDBDBD",
  //   borderRadius: "4px",
  // },
}));