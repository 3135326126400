import { Modal } from "../modal/Modal";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { AuthRequest } from "../../api/user/types";
import { MouseEvent, useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useAuth } from "../../context/userContext";
import { useModal } from "../../context/modalContext";

const AuthModal = () => {
  const modal = useModal();
  const { auth } = useAuth();
  const [authData, setAuthData] = useState<AuthRequest>({ userName: "", password: "" });
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const onAuth = () => {
    const { userName, password } = authData;
    if (userName && password) {
      auth(authData).finally(() => modal.close())
    }
  }

  return (
    <Modal
      type="AUTH_MODAL"
      title="Авторизация"
      rightBtn={{
        onClick: onAuth,
        text: "Войти",
        disabled: false,
      }}
    >

      <TextField
        label="Логин"
        sx={{mb: "12px"}}
        value={authData.userName}
        onChange={(event) => setAuthData(prev => ({ ...prev, userName: event.target.value }))}
        id="login_field"
        InputLabelProps={{
          shrink: true,
        }}
      />
      <TextField
        label="Пароль"
        value={authData.password}
        onChange={(event) => setAuthData(prev => ({ ...prev, password: event.target.value }))}
        id="password_field"
        type={showPassword ? 'text' : 'password'}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          endAdornment:
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>,
        }}
      />
    </Modal>
  )
}

export default AuthModal