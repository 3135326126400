import React from 'react';
import { ModalContextProvider } from "./context/modalContext";
import { UserContextProvider } from "./context/userContext";
import MainPage from "./pages/MainPage";

import { createTheme, ThemeProvider } from "@mui/material";
import { theme } from "./theme/theme";
import { ToastContextProvider } from "./context/toastContext";
import { Toast } from "./components/Toast/Toast";

const mainTheme = createTheme(theme);

function App() {
  return (
    <ModalContextProvider>
      <ToastContextProvider>
        <UserContextProvider>
          <ThemeProvider theme={mainTheme}>
            <MainPage />
            <Toast />
          </ThemeProvider>
        </UserContextProvider>
      </ToastContextProvider>
    </ModalContextProvider>
  );
}

export default App;
