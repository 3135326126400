import { BoxProps } from "@mui/material";

export enum ModalSize { SMALL = "400px", MIDDLE = "650px", BIG = "900px" }

export type ModalProps = {
  type: string,
  noClose?: boolean,
  backgroundColor?: string,
  title?: string,
  leftBtn?: ModalBtnProps,
  rightBtn?: ModalBtnProps,
  fullSize?: boolean,
  modalSize?: ModalSize | string,
  noScroll?: boolean,
  hideBackdrop?: boolean,
  height?: string,
}

export type ModalBtnProps = {
  onClick: () => void,
  text: string,
  disabled?: boolean,
}

export interface ModalContainerProps extends BoxProps {
  backgroundColor: string;
  fullSize: boolean
  noScroll: boolean
  modalSize: ModalSize | string,
}