import axios from "axios";
import { classifierService, patternService } from "../baseUrl";
import { CreateManufacturerRequest, Manufacturer, ManufacturerList, ManufacturerListRequest } from "./types";

export const getManufacturerListRequest = (params: ManufacturerListRequest) => axios.request<ManufacturerList>({
  url: "/api/device-manufacturer",
  baseURL: patternService,
  params
})

export const createManufacturerRequest = (data: CreateManufacturerRequest) => axios.request<Manufacturer>({
  url: "/api/device-manufacturer",
  method: "POST",
  baseURL: classifierService,
  data
})