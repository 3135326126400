import React, { FC, ReactElement, useEffect, useState } from "react";
import {
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  TextField
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

export type Option = {
  adornment?: ReactElement,
  name: string,
  value: string | number;
}

type CustomSelectProps = {
  label?: string
  options?: Option[]
  onChange?: (id?: string | number) => void
  getOptions?: (query: string) => Promise<Option[]>;
  search?: boolean,
  withAll?: boolean,
  footerBtn?: {
    onClick: () => void,
    text: string,
  }
  value?: Option | null,
}

const allOption = {
  name: "Все",
  value: "all",
}

const CustomSelect: FC<CustomSelectProps> = ({
  label,
  options: initOptions,
  getOptions,
  onChange,
  search = false,
  withAll = false,
  footerBtn,
  value,
}) => {
  const [options, setOptions] = useState<Option[]>(withAll ?
    [allOption, ...(initOptions || [])]
    : initOptions || []
  )
  const [selectedOption, setSelectedOption] = useState<Option | undefined>(withAll ? allOption : undefined);
  const [searchText, setSearchText] = useState<string>("");

  useEffect(() => {
    const timeout = setTimeout(() => {
      getOptions?.(searchText)
        .then((newOptions) =>
          setOptions(withAll ? [allOption, ...newOptions] : newOptions)
        )
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [searchText])

  useEffect(() => {
    if (initOptions !== undefined) {
      let selected = initOptions.find(item => item.value === selectedOption?.value)
      if (!selected && withAll) {
        selected = allOption
      }
      setSelectedOption(selected)
      setOptions(withAll ? [allOption, ...initOptions] : initOptions)
    }
  }, [initOptions])

  useEffect(() => {
    if (value !== undefined) {
      setSelectedOption(value || undefined)
    }
  }, [value])

  return (
    <FormControl fullWidth size="small">
      <InputLabel id="search-select-label">{label}</InputLabel>
      <Select
        // Disables auto focus on MenuItems and allows TextField to be in focus
        MenuProps={{ autoFocus: false }}
        labelId="search-select-label"
        id="search-select"
        value={selectedOption?.value || ""}
        label={label}
        onChange={(e) => {
          const selected = options.find(option => option.value === e.target.value)
          setSelectedOption(selected || undefined)
          onChange?.(selected?.value || undefined)
        }}
        renderValue={() => <>{selectedOption?.adornment} {selectedOption?.name}</>}
      >
        {footerBtn && <ListSubheader sx={{ mb: 2 }}>
            <Button sx={{ height: "40px" }} onClick={footerBtn.onClick} fullWidth>{footerBtn.text}</Button>
        </ListSubheader>}
        {search && <ListSubheader sx={{ mb: 2 }}>
            <TextField
                value={searchText}
                size="small"
              // Autofocus on textfield
                autoFocus
                placeholder="Type to search..."
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  )
                }}
                onChange={(e) => setSearchText(e.target?.value)}
                onKeyDown={(e) => {
                  if (e.key !== "Escape") {
                    // Prevents autoselecting item while typing (default Select behaviour)
                    e.stopPropagation();
                  }
                }}
            />
        </ListSubheader>}
        {options.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option?.adornment} &nbsp; {option.name}
          </MenuItem>))
        }
      </Select>
    </FormControl>
  );
}

export default CustomSelect
