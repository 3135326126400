import axios from "axios";
import { classifierService, patternService } from "../baseUrl";
import { CreateSeriesRequest, Series, SeriesList, SeriesListRequest } from "./types";

export const getSeriesListRequest = (params: SeriesListRequest) => axios.request<SeriesList>({
  url: "/api/device-series",
  baseURL: patternService,
  params
})

export const createSeriesRequest = (data: CreateSeriesRequest) => axios.request<Series>({
  url: "/api/device-series",
  method: "POST",
  baseURL: classifierService,
  data
})