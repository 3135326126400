import { ThemeOptions } from "@mui/material";

export const theme: ThemeOptions = {
  typography: {
    fontFamily: "Open Sans,sans-serif",
    h1: {
      fontSize: "56px",
      lineHeight: "72px",
      fontWeight: "700",
    },
    h2: {
      fontSize: "48px",
      lineHeight: "60px",
      fontWeight: "700",
    },
    h3: {
      fontSize: "40px",
      lineHeight: "48px",
      fontWeight: "700",
    },
    h4: {
      fontSize: "32px",
      lineHeight: "36px",
      fontWeight: "700",
    },
    h5: {
      fontSize: "24px",
      lineHeight: "36px",
      fontWeight: "700",
    },
    h6: {
      fontSize: "20px",
      lineHeight: "24px",
      fontWeight: "700",
    },
    subtitle1: {
      fontSize: "24px",
      lineHeight: "36px",
      fontWeight: "700",
    },
    subtitle2: {
      fontSize: "18px",
      lineHeight: "24px",
      fontWeight: "700",
    },

    body1: {
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: "400",
    },
    body2: {
      fontSize: "14px",
      lineHeight: "22px",
      fontWeight: "400",
    },
    caption: {
      fontSize: "12px",
      lineHeight: "12px",
      fontWeight: "400",
    },
  },
  spacing: (factor: number) => `${factor * 6}px`,
  components: {
    MuiButton: {
      defaultProps: {
        variant: "contained",
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          height: "48px",
          textTransform: "none",
          fontSize: "16px",
          fontWeight: 600,
          boxShadow: "none",
          "&:hover": {
            boxShadow: "none",
          },
          transition: "all .1s ease-in-out",
        },
      },
    },

    MuiCheckbox: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          boxShadow: "none",
          "&:hover": {
            background: "none",
          },
        },
      },
    }
  },
};