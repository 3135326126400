import React, { FC } from "react";
import { ColumnContainer } from "style";
import { Box, Button, Divider, Pagination, TextField, Typography } from "@mui/material";
import { toLocalDate } from "utility/toLocalDate";
import { useModal } from "context/modalContext";
import { DashboardItem, DashboardList, DashboardListRequest } from "api/dashboard/types";
import { statusPatternInfo } from "../../constants/bData";
import Select, { Option } from "../base/select/select";
import { Pattern } from "../../api/pattern/types";
import DateRange from "../base/date/dateRange";
import Popup from "../base/popup/popup";
import SearchIcon from '@mui/icons-material/Search';
import {
  deleteDashboardFileRequest, deleteDashboardPatternRequest
} from "../../api/dashboard/dashboard";
import { useToast } from "../../context/toastContext";
import { useAuth } from "../../context/userContext";

interface Props {
  dashboard?: DashboardList,
  onSearch: (params?: DashboardListRequest) => void
  selectedDashboardItem?: DashboardItem,
  pattern?: Pattern,
  onSelectPattern: (pattern: DashboardItem) => void
  statusList?: Option[],
}

const Dashboard: FC<Props> = ({
  dashboard,
  onSearch,
  selectedDashboardItem,
  onSelectPattern,
  pattern,
  statusList,
}) => {
  const { isAuth } = useAuth();
  const modal = useModal();
  const toast = useToast();

  const deleteDashboardItem = (item: DashboardItem) => () => {
    modal.open({ type: "PRELOADER_MODAL" })
    const request = item.patternId ?
      () => deleteDashboardPatternRequest(item.patternId)
      : () => deleteDashboardFileRequest(item.id);
    request().then(() => {
      toast.success("Лекало удалено")
      onSearch()
    })
      .catch(() => {
        toast.error("Не получилось удалить лекало")
      })
      .finally(() => {
        modal.close();
      })
  }

  return (
    <ColumnContainer>
      <TextField
        sx={{ mb: 1 }}
        size="small"
        label="Поиск по имени файла/лекала"
        onChange={(event) => onSearch({ name: event.target.value })}
        InputProps={{
          startAdornment: <SearchIcon />,
        }}
      />
      <Box display="grid" gap="12px" gridTemplateColumns="auto 150px">
        <Select
          label="Статус"
          options={statusList}
          withAll
          onChange={(value) => onSearch({ status: value as string })}
        />
        <DateRange
          onChange={onSearch}
        />
      </Box>
      <Box flex={1} overflow="scroll" mb={2}>
        <Box
          sx={{
            position: "sticky",
            top: -1,
            mb: "6px",
            display: "grid",
            columnGap: "20px",
            gridTemplateColumns: "auto 55px 70px 15px",
            backgroundColor: "white"
          }}
        >
          <Typography height="24px" overflow="hidden">Имя</Typography>
          <Typography>Статус</Typography>
          <Typography>Дата</Typography>
          <Divider />
          <Divider />
          <Divider />
        </Box>
        {dashboard?.content?.map(item => (
          <Box
            sx={{
              mb: 1,
              display: "grid",
              gap: "20px",
              gridTemplateColumns: "auto 55px 70px 15px",
              backgroundColor: item.id === selectedDashboardItem?.id ? "#bbb" : "inherit",
              justifyItems: "center",
              alignItems: "center",
              ":hover": {
                backgroundColor: "#eee",
              }
            }}
            key={item.id}
            onClick={() => onSelectPattern(item)}
          >
            <Typography sx={{ wordBreak: "break-all", justifySelf: "start" }}
                        variant="body2">{item.patternName || item.name}</Typography>
            <Popup
              popupText={statusPatternInfo[item.statusCode]?.text || ""}
            >
              {statusPatternInfo[item.statusCode]?.icon}
            </Popup>
            <Typography>{toLocalDate(item.createdAt)}</Typography>
            {(!item.patternId || (item.patternId && isAuth)) && <img
              src="/icons/trash.svg" alt=""
              width="24px"
              height="24px"
              onClick={() => modal.open({
                type: "CONFIRM_MODAL",
                data: {
                  content: "Вы точно хотите удалить лекало?",
                  submitText: "Да",
                  onSubmit: deleteDashboardItem(item)
                }
              })}
            />}
          </Box>
        ))}
      </Box>

      <Pagination
        sx={{ mb: 2 }}
        count={dashboard?.totalPages || 0}
        page={(dashboard?.number || 0) + 1}
        onChange={(event, value: number) => onSearch({ page: value - 1 })}
      />
      <Box sx={{
        border: "1px solid #c4c4c4",
        borderRadius: "4px",
        height: "150px",
        width: "100%",
        overflowY: "scroll",
        overflowX: "hidden",
        p: 1,
        mb: 2,
      }}>
        {pattern?.messages.map(message =>
          <Typography sx={{ wordBreak: "break-all" }} key={message.id} mb={1}>{message.text}</Typography>
        )}
      </Box>
      <Button onClick={() => modal.open({ type: "ADD_FILE", data: { afterLoad: onSearch } })}>
        Загрузить лекала
      </Button>
    </ColumnContainer>
  )
}

export default Dashboard;