import { createContext, FC, PropsWithChildren, useContext, useState } from "react";

export interface ModalState {
  type?: string;
  data?: any;
}

interface ModalContext {
  state: ModalState,
  open: (payload: ModalState) => void,
  close: () => void,
}

const ModalContext = createContext<ModalContext | undefined>(undefined);

export const ModalContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [state, setState] = useState<ModalState>({ type: undefined, data: undefined });

  const modal = {
    state,
    open: setState,
    close: () => setState({ type: undefined, data: undefined }),
  };

  return <ModalContext.Provider value={modal}>{children}</ModalContext.Provider>;
};

export const useModal = () => {
  const context = useContext(ModalContext);
  if (context === undefined) {
    throw new Error("useModal must be used within a ModalContextProvider");
  }

  return context;
};