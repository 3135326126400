import { TreeNodeState } from "../components/patternParams/patternParams";


export const device = [
  { code: "smartphone", name: "Смартфон" },
  { code: "tablet", name: "Планшет" },
  { code: "watch", name: "Часы" },
  { code: "camera", name: "Камера" },
  { code: "console", name: "Консоль" },
  { code: "car_radio", name: "Автомагнитола" },
  { code: "graphics_tablet", name: "Графический планшет" },
  { code: "smartphone_camera", name: "Камера на смартфон" },
  { code: "cash_terminal", name: "Кассовый терминал" },
  { code: "navigator", name: "Навигатор" },
  { code: "laptop", name: "Ноутбук" },
  { code: "player", name: "Плеер" },
  { code: "other", name: "Прочее" },
  { code: "tobacco_heating_system", name: "Система нагревания табака" },
  { code: "tester_scanner", name: "Тестер, Сканер" },
  { code: "test_patterns", name: "Тестовые лекала" },
  { code: "ebook", name: "Электронная книга" },
  { code: "echo_sounder", name: "Эхолот" }
]

export enum statusPattern {
  TEMP_VISIBLE = "TEMP_VISIBLE",
  VALIDATED = "VALIDATED",
  DRAFT = "DRAFT",
  VALIDATION = "VALIDATION",
  NEED_CORRECT = "NEED_CORRECT",
  TECHNICAL_VALIDATION = "TECHNICAL_VALIDATION",
}

export const statusPatternInfo = {
  [statusPattern.TEMP_VISIBLE]: {
    text :"Временное отображение",
    icon: <img src="/icons/temp_visible.svg" height="20px" width="20px"/>,
  },
  [statusPattern.VALIDATED]: {
    text :"Завершено",
    icon: <img src="/icons/validated.svg" height="20px" width="20px"/>,
  },
  [statusPattern.DRAFT]: {
    text :"Не завершена параметризация",
    icon: <img src="/icons/draft.svg" height="20px" width="20px"/>,
  },
  [statusPattern.VALIDATION]: {
    text :"Передано на валидацию",
    icon: <img src="/icons/validation.svg" height="20px" width="20px"/>,
  },
  [statusPattern.NEED_CORRECT]: {
    text :"Требуется коррекция",
    icon: <img src="/icons/need_correct.svg" height="20px" width="20px"/>,
  },
  [statusPattern.TECHNICAL_VALIDATION]: {
    text :"Техническая валидация",
    icon: <img src="/icons/technical_validation.svg" height="20px" width="20px"/>,
  },
}

export const statusPatternSelect = [
  {
    name: "Временное отображение",
    code: "TEMP_VISIBLE"
  },
  {
    name: "Завершено",
    code: "VALIDATED"
  },
  {
    name: "Не завершена параметризация",
    code: "DRAFT"
  },
  {
    name: "Передано на валидацию",
    code: "VALIDATION"
  },
  {
    name: "Требуется коррекция",
    code: "NEED_CORRECT"
  },
  {
    name: "Техническая валидация",
    code: "TECHNICAL_VALIDATION"
  }
]

export type PatternParams = {
  name: string,
  code: string,
  paramsType?: "or" | "and" | "bool",
  checked?: boolean,
  title?: boolean,
  params?: PatternParams[]
};

export const patternParams: PatternParams[] = [
  {
    code: "side",
    name: "Сторона",
    title: true,
    paramsType: "or",
    params: [
      {
        name: "Экран",
        code: "screen",
        paramsType: "and",
        params: [
          {
            name: "Типоразмер лекала",
            code: "pattern_size",
            title: true,
            paramsType: "or",
            params: [
              {
                name: "Полноэкранное",
                code: "fullscreen",
                checked: true,
              },
              {
                name: "Под чехол",
                code: "under_cover",
              },
              {
                name: "Под универсальный чехол",
                code: "under_the_universal_cover",
              },
            ]
          },
          {
            name: "Вырезы",
            code: "cutout",
            title: true,
            paramsType: "or",
            params: [
              {
                name: "Все отверстия",
                code: "all_holes",
                checked: true,
              },
              {
                name: "Только динамик",
                code: "speaker_only",
              },
              {
                name: "Чёлка",
                code: "bang",
              },
            ]
          },
          {
            name: "Второй экран",
            code: "second_screen",
            title: true,
            paramsType: "bool",
            params: [
              {
                name: "Да",
                code: "true",
                checked: true,
              },
              {
                name: "Нет",
                code: "false",
              },
            ]
          },
        ]
      },
      {
        name: "Корпус",
        code: "back",
        checked: true,
        paramsType: "and",
        params: [
          {
            name: "Логотип",
            code: "logo",
            title: true,
            paramsType: "bool",
            params: [
              {
                name: "Да",
                code: "true",
                checked: true,
              },
              {
                name: "Нет",
                code: "false",
              },
            ]
          },
          {
            name: "Блок камеры",
            code: "camera_block",
            title: true,
            paramsType: "bool",
            params: [
              {
                name: "Да",
                code: "true",
                checked: true,
              },
              {
                name: "Нет",
                code: "false",
              },
            ]
          },
          {
            name: "На чехол",
            code: "on_the_case",
            title: true,
            paramsType: "bool",
            params: [
              {
                name: "Да",
                code: "true",
                checked: true,
              },
              {
                name: "Нет",
                code: "false",
              },
            ]
          },
          {
            name: "Для какой пленки подходит",
            code: "which_film",
            title: true,
            paramsType: "and",
            params: [
              {
                name: "Цветная",
                code: "color",
                paramsType: "bool",
              },
              {
                name: "Прозрачная",
                code: "clear",
                paramsType: "bool",
              },
            ]
          },
          {
            name: "Лекало 360",
            code: "pattern_360",
            title: true,
            paramsType: "or",
            params: [
              {
                name: "Нет",
                code: "no",
                checked: true,
              },
              {
                name: "Слитное",
                code: "one_piece",
              },
              {
                name: "Раздельное",
                code: "separate",
              },
            ]
          },
        ]
      },
    ]
  },
  {
    name: "Показывать в конфигураторе",
    code: "showConfigurator",
    title: true,
    paramsType: "bool",
    params: [
      {
        name: "Да",
        code: "true",
        checked: true,
      },
      {
        name: "Нет",
        code: "false",
      },
    ]
  },
  {
    name: "Используемый размер заготовки",
    code: "size",
    title: true,
    paramsType: "or",
    params: [
      {
        name: "Смартфон",
        code: "smartphone",
        checked: true,
      },
      {
        name: "Часы",
        code: "watch",
      },
      {
        name: "Планшет",
        code: "tablet",
      },
      {
        name: "Большой планшет",
        code: "big_tablet",
      },
      {
        name: "Ни одна из заготовок",
        code: "nothing",
      },
    ]
  },
]


const getParams = (property: TreeNodeState) => {
  const { code, paramsType, params, checked } = property;
  let data: any = { code }

  switch (paramsType) {
    case "or":
      const checkedParam = params?.find(param => param.checked)
      if (checkedParam) {
        data.value = getParams(checkedParam)
      }
      break;
    case "bool":
      data.value = params
        ? params.find(param => param.checked)?.code === "true" :
        checked
      break;
    case "and":
      if (params) data = { ...data, ...getData(params) }
      break;
  }
  return data
}

export const getData = (properties: TreeNodeState[]) => {
  const data: any = {}
  properties.forEach((property) => {
    data[property.code] = getParams(property)
  })
  return data
}

// @ts-ignore
export const parseData = (properties: any, params?: PatternParams[]) => {
  const defaultParams = params || patternParams;

  // @ts-ignore
  return defaultParams.map(param => {
    const { code, value, ...props } = properties[param.code] || {}

    if (value !== undefined) {
      if (typeof value === "object") {
        return {
          ...param,
          params: param.params?.map(item => {
            const result = {
              ...item,
              checked: item.code === value.code
            }
            if (item.params && item.code === value.code) {
              // @ts-ignore
              result.params = parseData(value, item.params)
            }
            return result
          })
        }
      } else {
        if (param.params) {
          return {
            ...param,
            params: param.params.map(item => ({
              ...item,
              checked: item.code === `${value}`
            }))
          }
        } else {
          return {
            ...param,
            checked: value,
          }
        }
      }
    } else {
      return {
        ...param,
        params: parseData(props, param.params)
      }
    }

  })
}
