import axios from "axios";
import { classifierService, patternService } from "../baseUrl";
import { CreateModelRequest, Model, ModelList, ModelListRequest } from "./types";

export const getModelListRequest = (params: ModelListRequest) => axios.request<ModelList>({
  url: "/api/device-model",
  baseURL: patternService,
  params
})

export const createModelRequest = (data: CreateModelRequest) => axios.request<Model>({
  url: "/api/device-model",
  method: "POST",
  baseURL: classifierService,
  data
})