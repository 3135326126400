import axios from "axios";
import { patternService } from "../baseUrl";
import { AuthRequest } from "./types";

export const authRequest = (data: AuthRequest) => axios.request({
  url: "/api/user/login",
  method: "POST",
  baseURL: patternService,
  data,
})

export const logoutRequest = () => axios.request({
  url: "/api/user/logout",
  method: "POST",
  baseURL: patternService,
})

export const getUserRequest = () => axios.request({
  url: "/api/user/info",
  baseURL: patternService,
})