import { FC, useEffect, useState } from "react";
import { Modal } from "../modal/Modal";
import Select, { Option } from "../base/select/select";
import { GetOptions } from "../patternParams/patternParams";
import { Box, Button, TextField, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { ModalSize } from "../modal/types";
import { ListResponse } from "../../api/baseTypes";
import { AxiosResponse } from "axios";
import { createDeviceTypeRequest, getDeviceTypeListRequest } from "../../api/deviceType/deviceType";
import { createSeriesRequest, getSeriesListRequest } from "../../api/deviceSeries/deviceSeries";
import { createManufacturerRequest, getManufacturerListRequest } from "../../api/deviceManufacturer/deviceManufacturer";
import { createModelRequest } from "../../api/deviceModel/deviceModel";
import { createDeviceClassifierRequest } from "../../api/deviceClassifier/deviceClassifier";
import { useModal } from "../../context/modalContext";
import { useToast } from "../../context/toastContext";

const getOptionsByReq = (req: (props: any) => Promise<AxiosResponse<ListResponse<any>>>): GetOptions => (query) => {
  return req({ name: query }).then(resp => {
    const content = resp.data.content
    return content.map(type => ({ value: type.id, name: type.name }))
  })
}

interface ClassifierItem {
  name?: string,
  id?: string,
  inputType?: "select" | "custom"
}

const initClassifierItem: ClassifierItem = {
  name: "",
  id: "",
  inputType: "select"
}

interface ItemProps {
  label: string
  showToggle?: boolean,
  inputType?: "select" | "custom",
  value: ClassifierItem,
  onChange: (value: ClassifierItem) => void
  getOptions?: (query: string) => Promise<Option[]>;
}

const Item: FC<ItemProps> = ({
  label,
  showToggle = true,
  getOptions,
  value,
  onChange
}) => {
  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    inputType: "select" | "custom",
  ) => {
    if (inputType !== null) {
      onChange({ inputType })
    }
  };

  return (
    <Box mb={2}>
      <Box display="flex" mb={1}>
        <Typography mr={2}>{label}</Typography>
        {showToggle && <ToggleButtonGroup
            color="primary"
            value={value.inputType}
            exclusive
            onChange={handleChange}
        >
            <ToggleButton sx={{ height: "22px" }} value="select">Выбрать из списка</ToggleButton>
            <ToggleButton sx={{ height: "22px" }} value="custom">Ввести вручную</ToggleButton>
        </ToggleButtonGroup>}
      </Box>
      {value.inputType === "custom"
        ? <TextField
          fullWidth
          size="small"
          value={value.name}
          onChange={e => onChange({ name: e.target.value })}
        />
        : <Select
          search
          getOptions={getOptions}
          onChange={id => onChange({ id: id as string })}
        />
      }
    </Box>
  )
}


const ClassifierCreateModal: FC = () => {
  const modal = useModal();
  const toast = useToast()
  const [key, setKey] = useState(0)
  const [deviceType, setDeviceType] = useState<ClassifierItem>(initClassifierItem)
  const [deviceManufacturer, setDeviceManufacturer] = useState<ClassifierItem>(initClassifierItem)
  const [deviceSeries, setDeviceSeries] = useState<ClassifierItem>(initClassifierItem)
  const [deviceModel, setDeviceModel] = useState<ClassifierItem>({ ...initClassifierItem, inputType: "custom" })

  const clearFields = () => {
    setDeviceType(initClassifierItem);
    setDeviceManufacturer(initClassifierItem);
    setDeviceSeries(initClassifierItem);
    setDeviceModel({...initClassifierItem, inputType: "custom"})
    setKey(prevState => prevState + 1)
  }

  const onChange = (type: string) => (value: ClassifierItem) => {
    const { inputType } = value
    switch (type) {
      case "deviceType":
        if (inputType) {
          setDeviceType({ ...initClassifierItem, inputType })
        } else {
          setDeviceType(prev => ({ inputType: prev.inputType, ...value }))
        }
        break;
      case "deviceManufacturer":
        if (inputType) {
          setDeviceManufacturer({ ...initClassifierItem, inputType })
          if (inputType === "custom" && deviceSeries.inputType !== "custom") {
            setDeviceSeries({ ...initClassifierItem, inputType })
          }
        } else {
          setDeviceManufacturer(prev => ({ inputType: prev.inputType, ...value }))
        }
        break;
      case "deviceSeries":
        if (inputType) {
          setDeviceSeries({ ...initClassifierItem, inputType })
        } else {
          setDeviceSeries(prev => ({ inputType: prev.inputType, ...value }))
        }
        break;
      case "deviceModel":
        if (inputType) {
          setDeviceModel({ ...initClassifierItem, inputType })
        } else {
          setDeviceModel(prev => ({ inputType: prev.inputType, ...value }))
        }
        break;
    }
  }

  const enableSubmit =
    ((deviceType.inputType === "select" && deviceType.id) || (deviceType.inputType === "custom" && deviceType.name))
    && ((deviceManufacturer.inputType === "select" && deviceManufacturer.id) || (deviceManufacturer.inputType === "custom" && deviceManufacturer.name))
    && ((deviceSeries.inputType === "select" && deviceSeries.id) || (deviceSeries.inputType === "custom" && deviceSeries.name))
    && ((deviceModel.inputType === "select" && deviceModel.id) || (deviceModel.inputType === "custom" && deviceModel.name))

  useEffect(() => {
    if (modal.state.type === "CLASSIFIER_CREATE_MODAL") {
      clearFields()
    }
  }, [modal])

  const createClassifier = async () => {
    if (enableSubmit) {
      const data = {
        deviceTypeId: "",
        deviceManufacturerId: "",
        deviceSeriesId: "",
        deviceModelId: "",
      }
      if (deviceType.inputType === "custom" && deviceType.name) {
        try {
          const deviceTypeResponse = await createDeviceTypeRequest({ name: deviceType.name })
          data.deviceTypeId = deviceTypeResponse.data.id
        }
        catch (e) {
          toast.error("Ошибка при добавлении нового типа")
          return
        }
      }
      if (deviceType.inputType === "select" && deviceType.id) {
        data.deviceTypeId = deviceType.id
      }
      if (deviceManufacturer.inputType === "custom" && deviceManufacturer.name) {
        try {
          const deviceManufacturerResponse = await createManufacturerRequest({ name: deviceManufacturer.name })
          data.deviceManufacturerId = deviceManufacturerResponse.data.id
        }
        catch (e) {
          toast.error("Ошибка при добавлении нового производителя")
          return
        }
      }
      if (deviceManufacturer.inputType === "select" && deviceManufacturer.id) {
        data.deviceManufacturerId = deviceManufacturer.id
      }
      if (deviceSeries.inputType === "custom" && deviceSeries.name) {
        try {
          const deviceSeriesResponse = await createSeriesRequest({ name: deviceSeries.name })
          data.deviceSeriesId = deviceSeriesResponse.data.id
        } catch (e) {
          toast.error("Ошибка при добавлении новой серии")
          return
        }
      }
      if (deviceSeries.inputType === "select" && deviceSeries.id) {
        data.deviceSeriesId = deviceSeries.id
      }
      if (deviceModel.inputType === "custom" && deviceModel.name) {
        try {
          const deviceModelResponse = await createModelRequest({ name: deviceModel.name })
          data.deviceModelId = deviceModelResponse.data.id
        } catch (e) {
          toast.error("Ошибка при добавлении новой модели")
          return
        }
      }
      if (deviceModel.inputType === "select" && deviceModel.id) {
        data.deviceModelId = deviceModel.id
      }
      if (data.deviceManufacturerId && data.deviceTypeId && data.deviceModelId && data.deviceSeriesId) {
        createDeviceClassifierRequest(data).then(() => {
          toast.success("Новая модель добавлена")
          modal.close();
        }).catch(() => {
          toast.error("Ошибка при добавлении новой модели")
        })
      } else {
        toast.warning("Не хватает данных для создания модели")
      }
    } else {
      toast.warning("Не хватает данных для создания модели")
    }
  }

  return (
    <Modal type="CLASSIFIER_CREATE_MODAL" modalSize={ModalSize.BIG}>
      <Box key={key}>
        <Item
          label="Устройство"
          value={deviceType}
          onChange={onChange("deviceType")}
          getOptions={getOptionsByReq(getDeviceTypeListRequest)}
        />
        <Item
          label="Производитель"
          value={deviceManufacturer}
          onChange={onChange("deviceManufacturer")}
          getOptions={getOptionsByReq(getManufacturerListRequest)}
        />
        <Item
          label="Серия"
          value={deviceSeries}
          onChange={onChange("deviceSeries")}
          getOptions={getOptionsByReq(getSeriesListRequest)}
          showToggle={deviceManufacturer.inputType === "select"}
        />
        <Item
          label="Модель"
          value={deviceModel}
          onChange={onChange("deviceModel")}
          showToggle={false}
        />
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Button onClick={modal.close}>Закрыть без сохранения</Button>
        <Button onClick={clearFields}>Очистить форму</Button>
        <Button disabled={!enableSubmit} onClick={createClassifier}>Сохранить модель</Button>
      </Box>
    </Modal>
  )
}

export default ClassifierCreateModal