import React, { useEffect, useMemo, useRef, useState } from "react";

import UploadFileModal from "components/uploadFileModal/uploadFileModal";
import Dashboard from "components/dashboard/dashboard";
import PatternParamsComponent from "components/patternParams/patternParams";

import { ContentContainer, PageContainer } from "style";
import PatternView from "components/patternView/patternView";
import { DashboardItem, DashboardList, DashboardListRequest } from "api/dashboard/types";
import { getDashboardRequest } from "api/dashboard/dashboard";
import { InfoModal } from "../components/modal/InfoModal";
import { PreloaderModal } from "../components/modal/PreloaderModal";
import AuthModal from "../components/authModal/authModal";
import useRequest from "../api/useRequest";
import { Pattern, PatternStatusResponse } from "../api/pattern/types";
import { getPatternRequest, getPatternStatusRequest } from "../api/pattern/pattern";
import { ConfirmModal } from "../components/modal/ConfirmModal";
import { statusPatternInfo } from "../constants/bData";

export default function MainPage() {
  const [selectedDashboardItem, setSelectedDashboardItem] = useState<DashboardItem | undefined>()
  const [getStatusList, statusList] = useRequest<PatternStatusResponse>(getPatternStatusRequest)
  const [getList, dashboard] = useRequest<DashboardList, DashboardListRequest>(getDashboardRequest, {
    delay: 500,
    onSuccess: (data) => {
      let selected: DashboardItem | undefined
      if (selectedDashboardItem) {
        selected = data.content.find(dashboardItem => dashboardItem.id === selectedDashboardItem.id)
      }
      if (!selected) {
        selected = data.content[0]
      }
      setSelectedDashboardItem(selected)
      onSelectPattern(selected)
      getStatusList()
    }
  })
  const [getPattern, pattern, resetPattern] = useRequest<Pattern, string>(getPatternRequest)

  const searchData = useRef<DashboardListRequest>({})

  const onSearch = ({ name, status, beginDate, endDate, page }: DashboardListRequest = {}) => {
    if (status !== undefined) searchData.current.status = status
    if (status === "all") searchData.current.status = undefined
    if (name !== undefined) searchData.current.name = name
    if (beginDate !== undefined) searchData.current.beginDate = beginDate || undefined;
    if (endDate !== undefined) searchData.current.endDate = endDate || undefined;
    getList({ ...searchData.current, page })
  }

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search)
    const patternId = queryParams.get("patternId")
    if (patternId) {
      getPattern(patternId)
    } else {
      getList()
    }
    getStatusList()
  }, [])

  const preparedStatusList = useMemo(() => statusList?.content.map(code => ({
    adornment: statusPatternInfo[code.code]?.icon,
    value: code.id,
    name: code.name + " (" + code.patternCount + ")",
  })), [statusList])

  const onSelectPattern = (dashboardItem: DashboardItem) => {
    if (dashboardItem?.patternId) {
      getPattern(dashboardItem?.patternId)
    } else {
      resetPattern()
    }
    setSelectedDashboardItem(dashboardItem)
  }

  return (
    <PageContainer>
      <ContentContainer>
        <Dashboard
          dashboard={dashboard}
          onSearch={onSearch}
          selectedDashboardItem={selectedDashboardItem}
          pattern={pattern}
          onSelectPattern={onSelectPattern}
          statusList={preparedStatusList}
        />
        <PatternParamsComponent
          getList={onSearch}
          selectedDashboardItem={selectedDashboardItem}
          pattern={pattern}
        />
        <PatternView
          getList={onSearch}
          selectedDashboardItem={selectedDashboardItem}
          pattern={pattern}
        />
      </ContentContainer>
      <UploadFileModal />
      <InfoModal />
      <PreloaderModal />
      <AuthModal />
      <ConfirmModal />
    </PageContainer>
  );
}
