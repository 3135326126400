import { FC, PropsWithChildren } from "react";
import { Box, Button, Modal as MuiModal, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import * as React from "react";
import { ModalProps, ModalSize } from "components/modal/types";
import { ModalContainer, FlatIconButton } from "components/modal/style";
import { useModal } from "context/modalContext";

export const Modal: FC<PropsWithChildren<ModalProps>> = ({
  children,
  type,
  backgroundColor = "white",
  noClose = false,
  title,
  leftBtn,
  rightBtn,
  fullSize = false,
  modalSize = ModalSize.SMALL,
  height,
  noScroll = false,
  hideBackdrop = false,
}) => {
  const modal = useModal();

  return (
    <MuiModal
      hideBackdrop={hideBackdrop}
      open={type === modal.state.type}
      onClose={() => {
        !noClose && modal.close();
      }}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <ModalContainer
        backgroundColor={backgroundColor}
        fullSize={fullSize}
        modalSize={modalSize}
        noScroll={noScroll}
        height={height}
      >
        {!noClose && <Box position="absolute" top="4px" right="2px">
            <FlatIconButton
                id="close_modal_btn"
                onClick={modal.close}
            >
                <Close
                    sx={{
                      width: "20px",
                      height: "20px",
                    }}
                />
            </FlatIconButton>
        </Box>}

        {title && <Typography variant="subtitle2" color="primary" mb={3}>
          {title}
        </Typography>}

        {children}

        {(leftBtn || rightBtn) && <Box display="flex" mt={3}>
          {leftBtn &&
              <Button
                  id="left_modal_btn"
                  onClick={leftBtn.onClick}
                  sx={{ mr: 2 }}
                  fullWidth
                  disabled={leftBtn.disabled}
              >
                {leftBtn.text}
              </Button>
          }
          {rightBtn &&
              <Button
                  id="right_modal_btn"
                  onClick={rightBtn.onClick}
                  fullWidth
                  disabled={rightBtn.disabled}
              >
                {rightBtn.text}
              </Button>
          }
        </Box>}
      </ModalContainer>
    </MuiModal>
  );
};