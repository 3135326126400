import { AxiosError, AxiosResponse } from "axios";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { useAuth } from "../context/userContext";
import { useModal } from "../context/modalContext";
import { useToast } from "../context/toastContext";

type Request<R, T = undefined> = (props?: T) => Promise<AxiosResponse<R>>

interface Options<R, T = undefined> {
  delay?: number,
  onSuccess?: (data: R) => void
  onError?: (error: AxiosError) => void
  onFinally?: () => void
}

export default function useRequest<R, T = undefined>(req: (props: T) => Promise<AxiosResponse<R>>, options:Options<R, T> = {}):[Request<R,T>, R | undefined, () => void] {
  const { delay = 0, onSuccess, onError, onFinally } = options
  const {logout} = useAuth()
  const modal = useModal();
  const toast = useToast();
  const [response, setResponse] = useState<R | undefined>()
  const timeoutRef = useRef<NodeJS.Timeout | undefined>();

  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current)
    }
  }, [])

  const request:Request<R,T> = (props) => new Promise((resolve, reject) => {
    clearTimeout(timeoutRef.current)

    timeoutRef.current = setTimeout(() => {
      //@ts-ignore
      req(props)
        .then(resp => {
          setResponse(resp.data)
          onSuccess?.(resp.data)
          resolve(resp)
        })
        .catch(error => {
          if (error.response?.status === 401) {
            logout()
            modal.open({ type: "AUTH_MODAL" })
          }
          if (error.response?.status > 500) {
            toast.error("Ошибка сервера")
          }
          onError?.(error)
        })
        .finally(onFinally)
    }, delay)
  })

  return [
    request,
    response,
    () => setResponse(undefined)
  ]
}