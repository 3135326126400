import React, { FC, useEffect, useMemo, useState } from "react";

import { ColumnContainer } from "style";
import { downloadFileRequest } from "../../api/file/file";
import { Box, Button, TextField, Typography } from "@mui/material";
import { useAuth } from "../../context/userContext";
import { useModal } from "../../context/modalContext";
import {
  acceptPatternRequest,
  addCommentPatternRequest,
  rejectPatternRequest,
  technicalValidationPatternRequest,
  updatePatternFileRequest
} from "../../api/pattern/pattern";
import { statusPattern } from "../../constants/bData";
import { DashboardItem, DashboardListRequest } from "../../api/dashboard/types";
import useRequest from "../../api/useRequest";
import { CommentRequest, Pattern } from "../../api/pattern/types";
import { useToast } from "../../context/toastContext";

interface Props {
  getList: (params?: DashboardListRequest) => void
  selectedDashboardItem?: DashboardItem
  pattern?: Pattern,
}

const PatternView: FC<Props> = ({ selectedDashboardItem, getList, pattern }) => {
  const { isAuth, logout } = useAuth();
  const modal = useModal();
  const toast = useToast();
  const [comment, setComment] = useState<string>("")
  const [img, setImg] = useState<{ src: string, name: string } | undefined>()
  const [addCommentRequest] = useRequest<Pattern, CommentRequest>(addCommentPatternRequest, {
    onSuccess: () => getList(),
    onError: () => toast.error("Не удалось оставить коментарий")
  })
  const addComment = (pattern: Pattern) => {
    addCommentRequest({ id: pattern.id, data: { text: comment } })
  }
  const [reject] = useRequest<Pattern, string>(rejectPatternRequest,{
    onSuccess: (pattern) => {
      toast.success("Переведено на доработку")
      addComment(pattern)
    },
    onError: () => toast.error("Не удалось вернуть на доработку")
  })
  const [accept] = useRequest<Pattern, string>(acceptPatternRequest, {
    onSuccess: (pattern) => {
      toast.success("Подтверждено")
      addComment(pattern)
    },
    onError: () => toast.error("Не удалось подтвердить лекало")
  })
  const [technicalValidation] = useRequest<Pattern, string>(technicalValidationPatternRequest, {
    onSuccess: (pattern) => {
      toast.success("Переведено на техническую валидацию")
      addComment(pattern)
    },
    onError: () => toast.error("Не удалось подтвердить лекало")
  })

  const patternInfo = useMemo(() => {
    return {
      fileId: pattern?.svg || selectedDashboardItem?.fileId,
      patternName: pattern?.name || selectedDashboardItem?.patternName,
      patternId: pattern?.id || selectedDashboardItem?.patternId,
      name: selectedDashboardItem?.name || "",
      statusCode: pattern?.statusCode || selectedDashboardItem?.statusCode,
    }
  }, [selectedDashboardItem, pattern])

  useEffect(() => {
    setImg(undefined)
    if (patternInfo?.fileId) {
      const { fileId, patternName, patternId, name } = patternInfo
      downloadFileRequest(fileId).then(resp => {
        const contentDisposition = resp.headers["content-disposition"] || "";
        const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const match = fileNameRegex.exec(contentDisposition);
        const fileName = match?.[1].replace(/['"]/g, '') || name;
        const imgName = patternId ? patternName + " " + patternId : fileName
        
        setImg({ src: resp.data, name: imgName })
      })
        .catch(() => toast.error("Не удалось загрузить изображение лекала"))
    }
  }, [patternInfo])

  const validationBtnDisabled = !(patternInfo?.statusCode === statusPattern.VALIDATION
    || patternInfo?.statusCode === statusPattern.TECHNICAL_VALIDATION);

  return (
    <ColumnContainer>
      <Box display="flex" justifyContent="space-between"  mb={2}>
        <Typography sx={{ m: 1, wordBreak: "break-all", maxWidth: "65%"}}>
          {patternInfo?.patternName || patternInfo?.name || ""}
        </Typography>
        {!isAuth && <Button onClick={() => modal.open({ type: "AUTH_MODAL" })}>Режим валидатора</Button>}
        {isAuth && <Button onClick={logout}>Выйти</Button>}
      </Box>
      <Box flex={1} display="flex" justifyContent="center" alignItems="center" overflow="scroll" mb={2}>
        {img && <img src={img.src} alt="" width="90%" height="80%" />}
      </Box>
      <Box display="flex" justifyContent="space-between" padding="0 20px 0 50px">
        <Button
          sx={{ mr: 2 }}
          disabled={!(patternInfo?.patternId || img)}
          onClick={() => {
            if (img && patternInfo) {
              const link = document.createElement('a')
              link.href = img.src
              link.download = img.name
              link.click()
            }
          }}
        >Скачать файл</Button>
        <Button
          disabled={!patternInfo?.patternId}
          onClick={() => {
            if (patternInfo?.patternId) {
              const id = patternInfo.patternId
              modal.open({
                type: "ADD_FILE",
                data: {
                  uploadFile: (data: FormData) => updatePatternFileRequest(id, data),
                  afterLoad: getList,
                }
              })
            }
          }}
        >Загрузить файл</Button>
      </Box>

      {isAuth && <TextField
          sx={{ my: 2 }}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          label="Комментарий"
          multiline
          rows={5}
      />}
      {isAuth && <Box display="flex" justifyContent="space-between">
          <Button
              sx={{ mr: 2 }}
              disabled={validationBtnDisabled}
              onClick={() => {
                if (patternInfo?.patternId)
                  reject(patternInfo.patternId)
              }}
          >Вернуть на доработку</Button>
          <Button
              disabled={validationBtnDisabled}
              onClick={() => {
                if (patternInfo?.patternId)
                  if (patternInfo.statusCode === statusPattern.VALIDATION) {
                    technicalValidation(patternInfo.patternId)
                  } else {
                    accept(patternInfo.patternId)
                  }
              }}
          >Лекало правильное</Button>
      </Box>}
    </ColumnContainer>
  )
}

export default PatternView;