import axios from "axios";
import {
  CommentRequest,
  CreateUpdatePatternRequest,
  Pattern,
  PatternList,
  PatternListRequest,
  PatternStatusResponse
} from "./types";
import { patternService } from "../baseUrl";

export const getPatternListRequest = (params: PatternListRequest) => axios.request<PatternList>({
  url: "/api/pattern",
  baseURL: patternService,
  params
})

export const getPatternRequest = (id: string) => axios.request<Pattern>({
  url: `/api/pattern/${id}`,
  baseURL: patternService,
})

export const createPatternItemRequest = (data: CreateUpdatePatternRequest) => axios.request<Pattern>({
  url: "/api/pattern",
  baseURL: patternService,
  method: "POST",
  data
})

export const updatePatternRequest = (id: string, data: CreateUpdatePatternRequest) => axios.request<Pattern>({
  url: `/api/pattern/${id}`,
  baseURL: patternService,
  method: "PUT",
  data
})

export const deletePatternRequest = (id: string) => axios.request({
  url: `/api/pattern/${id}`,
  baseURL: patternService,
  method: "DELETE",
})

export const sendToValidationPatternRequest = (id: string) => axios.request<Pattern>({
  url: `/api/pattern/${id}/validation`,
  baseURL: patternService,
  method: "POST",
})

export const technicalValidationPatternRequest = (id: string) => axios.request<Pattern>({
  url: `/api/pattern/${id}/technical-validation`,
  baseURL: patternService,
  method: "POST",
})

export const acceptPatternRequest = (id: string) => axios.request<Pattern>({
  url: `/api/pattern/${id}/accept`,
  baseURL: patternService,
  method: "POST",
})

export const rejectPatternRequest = (id: string) => axios.request<Pattern>({
  url: `/api/pattern/${id}/reject`,
  baseURL: patternService,
  method: "POST",
})

export const addCommentPatternRequest = ({ id, data }: CommentRequest) => axios.request<Pattern>({
  url: `/api/pattern/${id}/message`,
  baseURL: patternService,
  method: "POST",
  data,
})

export const updatePatternFileRequest = (patternId:string, data: FormData) => axios.request<Pattern>({
  url: `/api/pattern/${patternId}/update-file`,
  baseURL: patternService,
  method: "PATCH",
  data
})

export const rollbackPatternFileRequest = (patternId:string) => axios.request<Pattern>({
  url: `/api/pattern/${patternId}/update-file/rollback`,
  baseURL: patternService,
  method: "POST",
})

export const commitPatternFileRequest = (patternId:string) => axios.request<Pattern>({
  url: `/api/pattern/${patternId}/update-file/commit`,
  baseURL: patternService,
  method: "POST",
})

export const rollbackFileRequest = (patternId:string) => axios.request<Pattern>({
  url: `/api/pattern/${patternId}/rollback`,
  baseURL: patternService,
  method: "POST",
})

export const commitFileRequest = (patternId:string) => axios.request<Pattern>({
  url: `/api/pattern/${patternId}/commit`,
  baseURL: patternService,
  method: "POST",
})

export const setPatternVisibleRequest = (patternId:string) => axios.request<Pattern>({
  url: `/api/pattern/${patternId}/visible`,
  baseURL: patternService,
  method: "POST",
  params: { visible: true }
})

export const getPatternTypeRequest = () => axios.request<Pattern>({
  url: `/api/pattern-type`,
  baseURL: patternService,
})

export const getPatternStatusRequest = () => axios.request<PatternStatusResponse>({
  url: `/api/pattern-status`,
  baseURL: patternService,
})



