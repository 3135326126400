import React, { FC } from "react";
import { Modal } from "./Modal";
import { useModal } from "context/modalContext";
import { Box, CircularProgress, Typography } from "@mui/material";

export const PreloaderModal:FC = () => {
  const modal = useModal();
  const text = modal.state.data?.text;

  return (
    <Modal
      type="PRELOADER_MODAL"
      noClose
      backgroundColor={text ? "white" : "inherit"}
    >
      <Typography position="absolute">{text}</Typography>
      <Box display="flex" flex={1} justifyContent="center" alignItems="center">
        <CircularProgress />
      </Box>
    </Modal>
  );
};