import { Box } from "@mui/material";
import { BoxUpload } from "style";
import { Modal } from "components/modal/Modal";
import React, { FC, useRef } from "react";
import { uploadFileRequest } from "api/file/file";
import { useModal } from "context/modalContext";
import { useToast } from "../../context/toastContext";
import { AxiosError } from "axios";
import InfoMessage from "./InfoMessage";
import {
  commitFileRequest,
  commitPatternFileRequest,
  rollbackFileRequest,
  rollbackPatternFileRequest,
} from "../../api/pattern/pattern";

const UploadFileModal:FC = () => {
  const toast = useToast();
  const inputRef = useRef<HTMLInputElement>(null);
  const modal = useModal();

  const handleFiles = (files?: FileList | null) => {
    if (!files) return;
    if (files.length > 1) {
      toast.warning("Загрузите изображения по одному")
      return;
    }

    modal.close();

    const { uploadFile, rollbackFile, commitFile } = (() => {
      if (modal.state.data.uploadFile) {
        return {
          uploadFile: modal.state.data.uploadFile,
          rollbackFile: rollbackPatternFileRequest,
          commitFile: commitPatternFileRequest,
        }
      }

      return {
        uploadFile: uploadFileRequest,
        rollbackFile: rollbackFileRequest,
        commitFile: commitFileRequest,
      }
    })()

    let counter = 0

    Array.from(files).forEach((file) => {
      if (file.type.indexOf("image/svg+xml") !== -1) {
        let reader = new FileReader();
        reader.readAsDataURL(file); // конвертирует Blob в base64 и вызывает onload

        reader.onload = function() {
          modal.open({type: "PRELOADER_MODAL", data: { text: "Обработка файла" }})
          let blob = new Blob([reader.result as string], { type: file.type });
          const formData = new FormData();
          formData.append("file", blob, file.name);
          uploadFile(formData).then((resp: any) => {
            counter++;
            if (files.length === counter) {
              const infoContent = <Box>
                {resp?.data?.descriptions?.map((info: any) => <InfoMessage error={info?.error || false} text={info?.format || ""} />)}
              </Box>

              modal.open({
                type: "CONFIRM_MODAL",
                data: {
                  title: "Продолжить работу с файлом?",
                  submitText: "Продолжить",
                  onReject: () => {
                    rollbackFile(resp?.data?.patternId).finally(() => {
                      modal.state.data?.afterLoad()
                    })
                  },
                  onSubmit: () => {
                    commitFile(resp?.data?.patternId).finally(() => {
                      modal.state.data?.afterLoad()
                    });
                    modal.close();
                  },
                  content: infoContent
                }
              })
            }
          }).catch((error: AxiosError) => {
            modal.close();
            toast.error(file.name + " ошибка загрузки файла")
          })
        };

        reader.onerror = function(ev) {
          toast.error(file.name + " ошибка чтения файла")
        }

      } else {
        toast.error(file.name + " неверный формат файла")
      }
    });
  };

  return(
    <Modal type="ADD_FILE">
      <Box
        borderRadius="4px"
        position="fixed"
        top="50%"
        left="50%"
        sx={{
          transform: "translate(-50%, -50%)",
          background: "#fff",
        }}
      >
        <BoxUpload
          onDrop={(event) => handleFiles(event.dataTransfer.files)}
          onClick={() => inputRef.current?.click()}
        >
          <>
            <p>Нажмите на область для добавления изображения</p>
            <p>или перетащите изображение сюда</p>
          </>
        </BoxUpload>

        <input
          style={{ display: "none" }}
          type="file"
          accept="image/svg+xml"
          ref={inputRef}
          onChange={() => handleFiles(inputRef.current?.files)}
        />
      </Box>
    </Modal>
  )
}

export default UploadFileModal;