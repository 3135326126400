import axios from "axios";
import { classifierService, patternService } from "../baseUrl";
import { CreateDeviceTypeRequest, DeviceType, DeviceTypeList, DeviceTypeListRequest } from "./types";

export const getDeviceTypeListRequest = (params: DeviceTypeListRequest) => axios.request<DeviceTypeList>({
  url: "/api/device-type",
  baseURL: patternService,
  params
})

export const createDeviceTypeRequest = (data: CreateDeviceTypeRequest) => axios.request<DeviceType>({
  url: "/api/device-type",
  method: "POST",
  baseURL: classifierService,
  data
})