import { Box, BoxProps, styled, Theme } from "@mui/material";
import { ModalContainerProps } from "components/modal/types";

export const ModalContainer = styled(Box)<ModalContainerProps>(({
  theme,
  backgroundColor,
  fullSize,
  modalSize,
  noScroll,
}) => ({
  width: modalSize,
  minHeight: "200px",
  maxWidth: fullSize ? "100%" : "90%",
  maxHeight: fullSize ? "100%" : "90%",

  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  borderRadius: "4px",
  padding: theme.spacing(4),
  background: backgroundColor,
  overflow: noScroll ? "hidden" : "auto",

  position: "relative",
}));

export const FlatIconButton = styled((props: BoxProps) => <Box {...props} />)(
  ({ theme }: { theme: Theme }) => ({
    padding: theme.spacing(2),
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      fill: theme.palette.primary.main,
      color: theme.palette.primary.main,
      background: "transparent",
    },
  })
);