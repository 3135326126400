import { createContext, FC, PropsWithChildren, useContext, useEffect, useState } from "react";
import { AuthRequest } from "../api/user/types";
import { authRequest, getUserRequest } from "../api/user/user";
import axios from "axios";
import { useToast } from "./toastContext";

interface UserContext {
  isAuth: boolean,
  auth: (data: AuthRequest) => Promise<void>,
  getUser: () => void,
  logout: () => void,
}

const UserContext = createContext<UserContext | undefined>(undefined);

export const UserContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isAuth, setAuth] = useState<boolean>(false);
  const toast = useToast();

  const user:UserContext = {
    isAuth,
    auth: (data) => authRequest(data).then(resp => {
      const {value} = resp.data
      if (value) {
        toast.success("Авторизация успешна")
        localStorage.setItem("auth", value)
        axios.defaults.headers.common['Authorization'] = value
        setAuth(true)
      }
    }).catch(() => {
      toast.error("Не удалось авторизоваться")
      setAuth(false)
    }),
    getUser: () => getUserRequest().then(() => setAuth(true))
      .catch(error => {
        if (error.response?.status === 401) {
          localStorage.setItem("auth", "")
          setAuth(false)
        }
      }),
    logout: () => {
      localStorage.setItem("auth", "")
      setAuth(false)},
  };
  useEffect(() => {
    const localToken = localStorage.getItem("auth")
    if (localToken) {
      axios.defaults.headers.common['Authorization'] = localToken;
      user.getUser()
    }
  }, [])

  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
};

export const useAuth = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within a UserContextProvider");
  }

  return context;
};