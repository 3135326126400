import React from "react";
import MuiAlert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Snackbar from "@mui/material/Snackbar";
import { useToast } from "../../context/toastContext";

export const Toast = () => {
  const toast = useToast();
  const { messages } = toast.state;

  const handleClose = (id: number) => {
    toast.remove(id);
  };

  return (
    <Snackbar
      open={messages.length > 0}
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
    >
      <Box>
        {messages.map((message) => (
          <MuiAlert
            elevation={6}
            variant="filled"
            key={message.id}
            onClose={() => {
              handleClose(message.id);
            }}
            severity={message.type}
            sx={{ width: "100%", mb: 1 }}
          >
            {message.text}
          </MuiAlert>
        ))}
      </Box>
    </Snackbar>
  );
};
