import axios from "axios";
import { patternService } from "../baseUrl";
import { File } from "./types";

export const uploadFileRequest = (data: FormData) => axios.request<File>({
  url: "/api/dashboard/upload",
  method: "POST",
  baseURL: patternService,
  data,
})

export const downloadFileRequest = (id: string) => axios.request({
  url: "/api/file/download",
  method: "POST",
  params: { id },
  baseURL: patternService,
})