import React, { FC, useEffect, useMemo, useState } from "react";
import { ColumnContainer } from "style";
import { createPatternItemRequest, sendToValidationPatternRequest, setPatternVisibleRequest, updatePatternRequest } from "api/pattern/pattern";
import { getDeviceClassifierListRequest } from "api/deviceClassifier/deviceClassifier";
import { Box, Button } from "@mui/material";
import {  getData, parseData, PatternParams, patternParams, statusPattern } from "../../constants/bData";
import { CreateUpdatePatternRequest, Pattern } from "../../api/pattern/types";
import { DeviceClassifierItem } from "../../api/deviceClassifier/types";
import TreeBuilder from "../paramsTreeBuilder/TreeBuilder";
import { DashboardItem, DashboardListRequest } from "../../api/dashboard/types";
import { useModal } from "../../context/modalContext";
import Select, { Option } from "../base/select/select";
import { useToast } from "../../context/toastContext";
import useRequest from "../../api/useRequest";
import ClassifierCreateModal from "../classifierCreateModal/ClassifierCreateModal";

export interface TreeNodeState extends Omit<PatternParams, "params"> {
  checked: boolean,
  params?: TreeNodeState[],
}

function getInitBuilderState(params: PatternParams[]): TreeNodeState[] {
  return params.map<TreeNodeState>(param => {
    let nodeParams: TreeNodeState[] | undefined;
    if (param.params) {
      nodeParams = getInitBuilderState(param.params)
    }

    return { ...param, checked: param.checked || false, params: nodeParams }
  })
}

const initBuilderState = getInitBuilderState(patternParams);

export type GetOptions = (query: string) => Promise<Option[]>

type Props = {
  selectedDashboardItem?: DashboardItem,
  pattern?: Pattern,
  getList: (params?: DashboardListRequest) => void
}

const PatternParamsComponent: FC<Props> = ({ selectedDashboardItem, getList, pattern }) => {
  const modal = useModal();
  const toast = useToast();
  const [draftTree, setDraftTree] = useState(false)
  const [classifierList, setClassifierList] = useState<DeviceClassifierItem[]>([])
  const [selectedDeviceClassifier, setSelectedDeviceClassifier] = useState<DeviceClassifierItem | undefined>()
  const [treeNodeState, updateTreeNodeState] = useState<TreeNodeState[]>(initBuilderState)
  const [ setVisible ] = useRequest(setPatternVisibleRequest, {
    onSuccess: () => toast.success("Переведено во временное отображение"),
    onError: () => toast.error("Не удалось перевести лекало во временное отображение")
  })
  const [ sendToValidationPattern ] = useRequest(sendToValidationPatternRequest, {
    onSuccess: () => toast.success("Отправлено на валидацию"),
    onError: () => toast.error("Не удалось отправить лекало на валидацию")
  })

  const deviseClassifierValue = useMemo(() => selectedDeviceClassifier ? {
    name: selectedDeviceClassifier.name,
    value: selectedDeviceClassifier.id
  } : null, [selectedDeviceClassifier])

  useEffect(() => {
    let treeIsDraft = true;
    const stored = sessionStorage.getItem(selectedDashboardItem?.id || "")
    let newState = stored ? JSON.parse(stored) : undefined
    if (!newState && pattern?.property) {
      treeIsDraft = false
      newState = parseData(pattern?.property)
    }
    setDraftTree(treeIsDraft)
    updateTreeNodeState(newState || initBuilderState)
    if (pattern?.deviceClassifierId && pattern?.deviceClassifierName) {
      // @ts-ignore
      setSelectedDeviceClassifier({id: pattern.deviceClassifierId, name: pattern?.deviceClassifierName})
    } else {
      setSelectedDeviceClassifier(undefined)
    }
  }, [selectedDashboardItem, pattern])

  const getOptions: GetOptions = (query) => {
    return getDeviceClassifierListRequest({ name: query }).then(resp => {
      const content = resp.data.content
      setClassifierList(content)
      return content.map(classifier => ({ value: classifier.id, name: classifier.name }))
    })
  }

  const updateTree = () => {
    setDraftTree(true)
    updateTreeNodeState(prev => [...prev])
    if (selectedDashboardItem) {
      sessionStorage.setItem(selectedDashboardItem.id, JSON.stringify(treeNodeState))
    }
  }

  const savePattern = (onSuccess?: (id: string) => void) => {
    if (!selectedDeviceClassifier) {
      modal.open({ type: "INFO_MODAL", data: { content: "Выберите модель и тип устройства в справочнике" } })
      return
    }
    if (selectedDashboardItem && treeNodeState) {
      modal.open({ type: "PRELOADER_MODAL" })
      const result = {
        deviceClassifierId: selectedDeviceClassifier.id,
        svg: selectedDashboardItem.fileId,
        property: getData(treeNodeState)
      }
      let save = createPatternItemRequest
      if (pattern) {
        save = (result: CreateUpdatePatternRequest) => updatePatternRequest(pattern.id, result)
      }

      save(result)
        .then(resp => {
          sessionStorage.removeItem(selectedDashboardItem.id)
          setDraftTree(false)
          try {
            onSuccess?.(resp.data.id)
          }
          catch {
            modal.close()
          }
        })
        .then(() => getList())
        .catch(() =>
          toast.error("Не удалось схранить лекало"))
        .finally(modal.close)
    }
  }

  const setPatternVisibleModalOpen = () => {
    modal.open({
      type: "CONFIRM_MODAL",
      data: {
        content: "Вы отправляете лекало на временное отображение без валидации",
        onSubmit: () => savePattern(setVisible)
      }
    })
  }

  const sendToValidation = () => savePattern(sendToValidationPattern)

  const visibleBtnDisabled = (selectedDashboardItem?.statusCode === statusPattern.DRAFT)
    || (selectedDashboardItem?.statusCode === statusPattern.VALIDATION)
    || (selectedDashboardItem?.statusCode === statusPattern.NEED_CORRECT)

  return (
    <ColumnContainer>
      <Select
        label="Поиск модели и типа устройства в справочнике"
        getOptions={getOptions}
        search
        onChange={id => setSelectedDeviceClassifier(classifierList.find(classifier => classifier.id === id))}
        footerBtn={{
          onClick: () => {modal.open({type: "CLASSIFIER_CREATE_MODAL"})},
          text: "Создать модель"
        }}
        value={deviseClassifierValue}
      />
      <Box sx={{
        flex: 1,
        overflow: "scroll"
      }}>
        <TreeBuilder draft={draftTree} params={treeNodeState} updateComponent={updateTree} />
      </Box>
      <Box display="flex" justifyContent="space-between" mt={2}>
        <Button
          disabled={!visibleBtnDisabled}
          sx={{ mr: 2 }}
          onClick={setPatternVisibleModalOpen}
        >Временное отображение</Button>
        <Button onClick={sendToValidation}>Отправить на валидацию</Button>
      </Box>
      <ClassifierCreateModal/>
    </ColumnContainer>
  )
}

export default PatternParamsComponent;