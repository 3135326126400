import axios from "axios";
import { CreateDCRequest, DeviceClassifier, DeviceClassifierList, DeviceClassifierRequest } from "./types";
import { classifierService } from "../baseUrl";

export const getDeviceClassifierListRequest = (params?: DeviceClassifierRequest) => axios.request<DeviceClassifierList>({
  url: "/api/device-classifier",
  baseURL: classifierService,
  params
})

export const createDeviceClassifierRequest = (data: CreateDCRequest) => axios.request<DeviceClassifier>({
  url: "/api/device-classifier",
  method: "POST",
  baseURL: classifierService,
  data
})