import * as React from 'react';
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import { Box, Typography } from "@mui/material";
import { Overwrite } from "utility/typeHelpers";

type BasicCheckboxProps = Overwrite<CheckboxProps, {
  label: string
  onChange?: (checked: boolean) => void
}>

export default function BasicCheckbox({
  label,
  onChange,
  ...props
}:BasicCheckboxProps) {

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange?.(event.target.checked)
  };

  return (
    <Box display="flex" alignItems="center">
      <Checkbox
        {...props}
        sx={{p: "2px"}}
        onChange={handleChange}
        inputProps={{ 'aria-label': 'controlled' }}
      />
      <Typography >{label}</Typography>
    </Box>
  );
}