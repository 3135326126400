import React, { FC, Fragment } from "react";
import { BuilderContainer } from "./style";
import Checkbox from "../base/checkbox/checkbox";
import { Typography } from "@mui/material";
import { TreeNodeState } from "../patternParams/patternParams";
import { CollapseProps } from "@mui/material/Collapse/Collapse";

interface TreeBuilderProps extends CollapseProps {
  params: TreeNodeState[],
  updateComponent: () => void,
  disabled?: boolean,
  paramsType?: "or" | "and" | "bool",
  draft?: boolean,
}

const TreeBuilder: FC<TreeBuilderProps> = ({
  params,
  paramsType,
  disabled,
  updateComponent,
  draft,
  ...props
}) => {
  const onChange = (param: TreeNodeState) => (value: boolean) => {
    if (paramsType !== "and") {
      if (value) {
        params.forEach(itemParam => itemParam.checked = false)
        param.checked = true;
      }
    } else {
      param.checked = value;
    }

    updateComponent();
  }

  return (
    <BuilderContainer {...props} in={!disabled}>
      {params.map((param) =>
        <Fragment key={param.code}>
          {param.title
            ? <Typography sx={{ height: "28px", ml: "4px", verticalAlign: "center" }}>{param.name}</Typography>
            : <Checkbox
              color={draft ? "error" : "primary"}
              disabled={disabled}
              label={param.name}
              checked={param.checked}
              onChange={onChange(param)}
            />
          }
          {param.params &&
              <TreeBuilder
                  draft={draft}
                  params={param.params}
                  paramsType={param.paramsType}
                  updateComponent={updateComponent}
                  disabled={(disabled || (paramsType && paramsType !== "and" && !param.checked))}
              />
          }
        </Fragment>
      )}
    </BuilderContainer>
  )
}

export default TreeBuilder;