import React, { FC } from "react";
import { Modal } from "./Modal";
import { useModal } from "context/modalContext";
import { Typography } from "@mui/material";

export const InfoModal: FC = () => {
  const modal = useModal();
  const { title, content, submitText = "Хорошо", onSubmit } = modal.state.data || {};

  return (
    <Modal
      type="INFO_MODAL"
      title={title}
      rightBtn={{
        onClick: () => {
          onSubmit?.();
          modal.close();
        },
        text: submitText,
      }}
    >
      {typeof content === "string" ?
        <Typography>{content}</Typography> :
        content
      }
    </Modal>

  );
};