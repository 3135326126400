import React, { FC } from "react";
import { Modal } from "components/modal/Modal";
import { useModal } from "context/modalContext";

export const ConfirmModal: FC = () => {
  const modal = useModal();
  const { title = "Внимание", content, submitText = "Хорошо", onSubmit, onReject } = modal.state.data || {};

  return (
    <Modal
      type="CONFIRM_MODAL"
      title={title}
      leftBtn={{
        onClick: () => {
          modal.close();
          onReject?.();
        },
        text: "Отмена",
      }}
      rightBtn={{
        onClick: onSubmit,
        text: submitText,
      }}
    >
      {content}
    </Modal>
  );
};