import axios from "axios";
import { DashboardList, DashboardListRequest } from "./types";
import { patternService } from "../baseUrl";

export const getDashboardRequest = (params?: DashboardListRequest) => axios.request<DashboardList>({
  url: "/api/dashboard",
  baseURL: patternService,
  params: { size: 10, ...params}
})

export const deleteDashboardFileRequest = (id: string) => axios.request({
  url: `/api/dashboard/file/${id}`,
  baseURL: patternService,
  method: "DELETE"
})

export const deleteDashboardPatternRequest = (id: string) => axios.request({
  url: `/api/dashboard/pattern/${id}`,
  baseURL: patternService,
  method: "DELETE"
})